import React from 'react';
import Link from 'next/link';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { onPageNavigationEvent, placementClickEvent } from 'src/lib/analytics';

import styles from './ArticleList.module.scss';

const ArticleList = ({
  featureName,
  featureShortName,
  featureCategory,
  articles,
  displayContext,
  clickLocation,
  parentPosition,
}) => (
  <ul className={styles.articles}>
    {articles.map((article, index) => (
      <li
        className={classNames(
          styles.articles__item,
          styles[`articles__item_${displayContext}`]
        )}
        key={uuidv4()}
      >
        <Link
          as={`/${featureCategory}/${featureShortName}/${moment(
            article.publishDate
          ).format('YYYY/MM/DD')}`}
          href="/[category]/[shortName]/[...publishDate]"
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <a
            className={classNames(
              styles.articles__link,
              index === 0
                ? styles.articles__featured
                : styles.articles__secondary
            )}
            onClick={
              clickLocation
                ? () => {
                    onPageNavigationEvent(clickLocation, 'link', article.title);
                    placementClickEvent(
                      clickLocation,
                      parentPosition
                        ? `${parentPosition}-${index + 1}`
                        : index + 1,
                      article.title,
                      featureName
                    );
                  }
                : undefined
            }
            onKeyUp={() =>
              clickLocation
                ? () => {
                    onPageNavigationEvent(clickLocation, 'link', article.title);
                    placementClickEvent(
                      clickLocation,
                      parentPosition
                        ? `${parentPosition}-${index + 1}`
                        : index + 1,
                      article.title,
                      featureName
                    );
                  }
                : undefined}
          >
            {article.title}
          </a>
        </Link>
      </li>
    ))}
  </ul>
);

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      publishDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  clickLocation: PropTypes.string,
  displayContext: PropTypes.oneOf(['card', 'latest']),
  featureCategory: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  // Note that parentPosition is used for placementClick
  // tracking of consecutive parent components that hold
  // ArticleList components. ie: Cards on category landers
  // or homepage Latest.
  parentPosition: PropTypes.number,
};

ArticleList.defaultProps = {
  displayContext: 'card',
  clickLocation: '',
  parentPosition: null,
};

export default ArticleList;
